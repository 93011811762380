import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/emergency-water-extraction';

import config from '../config';

const EmergencyWaterExtractionPage = () => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Emergency Water Extraction</h1>
      <p>
        Time is of the essence when a hot water heater breaks or any other flood
        occurs. The sooner the water is removed the less water damage. That is
        why Sci-Tech provides our water damage restoration service 7 days a
        week, 24 hours a day! We specialize in water damage restoration, water
        extraction, structural drying, carpet cleaning, and all fabric cleaning
        in The Roanoke and New River Valley. If a water problem is treated
        quickly it will prevent mold damage, mildew, and various types of
        bacterial growth that are present in standing water and your wet carpet
        and padding.
      </p>
      <p>
        Using our specialty water damage restoration equipment we are able to
        remove water through the carpet and padding all the way down to the
        sub-floor. This alone increases drying time and along with our powerful
        truck mounted equipment we are able to extract up to 1200 sq. ft. of
        saturated carpet in a single hour.
      </p>
      <p>
        IICRC Certified Water Damage Specialist: With the growing concern for
        dangers of mold it is important you know that the contractor being used
        understands the work they are performing for the protection of you and
        your community.
      </p>
      <p>Call now and we will be there very soon!</p>
      <p>
        <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
      </p>
      <p>
        <Link to="water-damage-dos-and-donts">
          Do's And Don'ts Until Hellp Arrives
        </Link>
      </p>
      <h2>What To Do When Your Carpet Gets Flooded</h2>
      <p>
        It happens all of the time. You come home from work to find water
        running out of your garage or front door. You rush inside to find that
        your water heater tank has sprung a leak and your entire house is now
        flooded. As your couch floats by you begin to wonder what to do. Here is
        some information that you need to know if the unthinkable happens to
        you:
      </p>
      <p>
        Water heaters are just one source of flooded home. Other sources include
        broken pipes, broken ice maker lines, clogged air conditioner drains and
        heavy rains to name a few. No matter what the source of the leak, there
        are steps that you need to take immediately to rectify the situation.
      </p>
      <p>
        The longer your belongings stay wet, the worse the water damage will be,
        so the first thing you should do is call a flood specialist. A water
        damage restoration company like Sci-Tech will have the equipment
        necessary to extract the water from your home and dry it quickly. Do not
        even think of tackling this problem yourself. A shop vac and some floor
        fans will just not get the job done. Professional flood restoration
        services will bring thousands of dollars worth of specialty equipment
        into your home to take care of the damage.
      </p>
      <p>
        Now that you have a water damage restoration company on the way, contact
        your insurance company. The quicker you let them know about the problem,
        the quicker you will get your claim settled and your life back in order.
        Many companies will immediately send an adjuster to the scene and others
        may choose to just contact you the next day. Once you get an adjuster,
        they may tell you that you need to use their restoration company but
        remember that they can not insist on it. They can only recommend a
        company. You have the right to use any company that you choose.
      </p>
      <p>
        After your water damage restoration company has extracted the water from
        your carpets and floors, they will set up powerful fans and
        dehumidification equipment. This equipment will completely dry your home
        over the next few days. After your carpet is dry, your adjuster will
        decide what is salvageable and what needs to be replaced.
      </p>
      <p>
        This is of course an over simplification of the process involved but it
        gives you an idea of what you need to do. Flood damage is never easy to
        clean up, but remember that you will eventually get through it with the
        help of Sci-Tech’s Restoration Services.
      </p>
      <p>Call now and we will be there very soon!</p>
      <p>
        <a href={config.phoneNumberHref}>{config.phoneNumber}</a>
      </p>
      <p>
        <Link to="water-damage-dos-and-donts">
          Take a look at our list of Water Damage Do’s and Don’ts.
        </Link>
      </p>
    </Wrapper>
  </Layout>
);

export default EmergencyWaterExtractionPage;
